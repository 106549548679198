<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <h1>LVV Consulting</h1>
      </v-col>
      <v-col cols="12" sm="8" md="4">
        <v-card flat>
          <v-card-title>Michael James</v-card-title>
          <v-card-text>
            <v-icon>mdi-email</v-icon> michael@lvvconsulting.com
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="8" md="4">
        <v-card flat>
          <v-card-title>Paul James</v-card-title>
          <v-card-text>
            <v-icon>mdi-email</v-icon> paul@lvvconsulting.com
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  name: 'Home',
  data() {
    return {}
  }
}
</script>

<style>
</style>
