<template>
  <v-app color="indigo">
    <v-content>
      <router-view></router-view>
    </v-content>
  </v-app>
</template>

<script>
export default {
  data() {
    return { }
  }
}
</script>

<style>

</style>